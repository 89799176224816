import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SplashHeader from "../common/header/SplashHeader";
import SplashFooter from "../common/footer/SplashFooter";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import ProcessOne from "../component/process/ProcessOne";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import AboutFive from "../component/about/AboutFive";
import EventsOne from "../component/about/EventsOne";
const Events = () => {
  return (
    <>
      <SEO title="About us" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <SplashHeader />
        <br></br>
        <div className="section section-padding case-study-featured-area">
          <div className="container">
            <div>
              <h2 style={{}}>Events</h2>
              <EventsOne />
              <FooterOne parentClass="" />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Events;
