import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaDribbble,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";

const SplashFooter = () => {
  return (
    <footer className="footer-area splash-footer">
      <div className="container">
        <div className="footer-bottom">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © Sovieve Academy {new Date().getFullYear()}. All rights
                  reserved
                </span>{" "}
                <span style={{ marginLeft: "20px" }}>
                  Made at{" "}
                  <a href="https://kaithero.com/" target="_blank">
                    Kaithero
                  </a>
                </span>
              </div>
            </div>
            <div className="col-lg-5">
              <ul className="footer-social list-unstyled">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/" target="_blank">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://linkedin.com/" target="_blank">
                    <FaLinkedin />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank">
                    <FaTwitter />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li></li>
                  <li>
                    <Link to="">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SplashFooter;
