import React from "react";
import { Link } from "react-router-dom";

const AboutFive = () => {
  return (
    <div className="section-padding-equal">
      <div className="container">
        <div className="row align-items-center">
          <div className="section-heading heading-left">
            <span className="subtitle" style={{ fontSize: "30px" }}>
              <b>Our Team</b>
            </span>
          </div>

          <div className="col-lg-6" style={{ marginTop: "10px" }}>
            <div className="about-team">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/genevieve1.jpg"}
                  alt="thumbnail"
                />
              </div>
              <h5>Genevieve Egbunno</h5>
              <h6>Executive Director</h6>
            </div>
          </div>

          <div
            className="col-lg-6"
            style={{ marginTop: "10px", zIndex: "100" }}
          >
            <div className="about-team">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/erioluwa.jpg"}
                  alt="thumbnail"
                />
              </div>
              <h5>Erioluwa Adenyika</h5>
              <h6>Curriculum/Content Developer</h6>
            </div>
          </div>
        </div>
        <div className="row align-items-center" style={{ marginTop: "10px" }}>
          <div className="col-lg-6">
            <div className="about-team">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/nkiru.jpg"}
                  alt="thumbnail"
                />
              </div>
              <h5>Nkiru Okpara</h5>
              <h6>Budgeting Officer</h6>
            </div>
          </div>

          <div className="col-lg-6" style={{ marginTop: "10px" }}>
            <div className="about-team">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/ramat.jpg"}
                  alt="thumbnail"
                />
              </div>
              <h5>Ramat Idakwoji</h5>
              <h6>Project Manager</h6>
            </div>
          </div>
          {/*       <div className="col-lg-6">
            <div className="about-team">
              <div className="section-heading heading-left">
                <span className="subtitle">Our Team</span>
                <h2>Alone we can do so little; together we can do so much.</h2>
                <p>
                  Donec metus lorem, vulputate at sapien sit amet, auctor
                  iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus
                  velit. Aliquam tristique libero.
                </p>
                <Link to="#" className="axil-btn btn-large btn-fill-primary">
                  Our Team
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
