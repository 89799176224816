import React, { useState } from "react";
import Logo from "../../elements/logo/Logo";
import StickyHeader from "./StickyHeader";
import OffcanvasMenu from "./OffcanvasMenu";
import SwitcherHeader from "../../elements/switcher/SwitcherHeader";
import MobileMenu from "./MobileMenu";

const SplashHeader = () => {
  const sticky = StickyHeader(100);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const OffcanvasHandleClose = () => setShowOffcanvas(false);
  const OffcanvasHandleShow = () => setShowOffcanvas(true);

  const MobileMenuHandler = () => {
    document.querySelector(".mobilemenu-popup").classList.toggle("show");
    document.querySelector("body").classList.toggle("mobilemenu-show");

    var elements = document.querySelectorAll(
      ".mobilemenu-popup .menu-item-has-children > a"
    );

    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".axil-submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
  };

  return (
    <>
      <header className="header axil-header header-style-1 splash-header-style">
        <div className={`axil-mainmenu ${sticky ? "axil-sticky" : ""}`}>
          <div className="container">
            <div className="header-navbar">
              <div className="header-logo">
                <Logo
                  limage="/images/logo.png"
                  dimage="/images/logo-3.png"
                  simage="/images/logo-2.png"
                />
              </div>
              <div className="header-main-nav">
                {/* Start Mainmanu Nav */}
                <nav className="mainmenu-nav">
                  <ul className="mainmenu">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="learninglab">Learning Lab</a>
                    </li>
                    <li>
                      <a href="gallery">Gallery</a>
                    </li>
                    <li>
                      <a href="events">Events</a>
                    </li>
                  </ul>
                </nav>
                {/* End Mainmanu Nav */}
              </div>
              <div className="header-action">
                <ul className="list-unstyled">
                  {" "}
                  {/* <li className="buy-btn">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href=""
                      className="axil-btn btn-fill-primary"
                    >
                      Donate
                    </a>
                  </li> */}
                  <li className="sidemenu-btn d-lg-block d-none">
                    <button className="btn-wrap" onClick={OffcanvasHandleShow}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </li>
                  <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                    <button className="btn-wrap" onClick={MobileMenuHandler}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <OffcanvasMenu
        offcanvasShow={showOffcanvas}
        offcanvasHide={OffcanvasHandleClose}
      />
      <MobileMenu MobileHandler={MobileMenuHandler} />
    </>
  );
};

export default SplashHeader;
