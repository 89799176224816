import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SplashHeader from "../common/header/SplashHeader";
import SplashFooter from "../common/footer/SplashFooter";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import ProcessOne from "../component/process/ProcessOne";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import AboutFive from "../component/about/AboutFive";
import LearningOne from "../component/about/LearningOne";
import LearningTwo from "../component/process/LearningTwo";

const LearningLab = () => {
  return (
    <>
      <SEO title="About us" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <SplashHeader />
        <BcrumbBannerOne
          title="Learning Lab"
          paragraph="Our Learning Lab is the engine that drives the Sovieve Academy."
          styleClass="thumbnail-4"
          mainThumb="/images/banner/labbanner.jpg"
        />
        <LearningOne />
        <LearningTwo />

        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default LearningLab;
