import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="aboutus">About Us</a>
        </li>
        <li>
          <a href="learninglab">Learning Lab</a>
        </li>
        <li>
          <a href="gallery">Gallery</a>
        </li>
        <li>
          <a href="events">Events</a>
        </li>
        <li className="buy-btn">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href=""
            className="axil-btn btn-fill-primary"
          >
            Donate
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
