import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SplashHeader from "../common/header/SplashHeader";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectFive from "../component/project/ProjectFive";

const Gallery = () => {
  return (
    <>
      <SEO title="Gallery" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <SplashHeader />
        <br></br>
        <ProjectFive />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Gallery;
