import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const LearningOne = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="case-study-featured-thumb text-start">
              <img
                src={process.env.PUBLIC_URL + "/images/others/lab2.jpg"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle"></span>
                <h2 className="title"></h2>
                <br></br>
                <p>
                  Our Learning Lab is the engine that drives the Sovieve
                  Academy. Through the learning Lab we hope to improve learning
                  outcomes and close gender gaps in rural through the Business
                  development training, leadership training and STEAM
                  disciplines, by leveraging technology tools and the science of
                  human learning to foster student success in online and hybrid
                  learning environments. In the Learning Lab we engage a team of
                  instructors to teach classes in various fields to help promote
                  financial literacy and build career paths for our
                  beneficiaries.
                </p>{" "}
              </div>
              {/*  <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="10" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Years on the market</span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? (
                            <CountUp end="1500" duration={1} />
                          ) : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">
                    Projects delivered so far
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningOne;
